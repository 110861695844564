<template>
<div class="recruit-box left clearfix">
  <Header></Header>
  <!--顶部banner开始-->
  <div class="aboutus-banner left"> <img src="~images/banner/xiangmujinzhanchaxun.jpg" width="100%" /> </div>

  <div class="contact-content width-bai left clearfix">
                <div class="contact-contents center clearfix" style="padding-top:30px;">

                <h1 style="margin-left:30px;">项目进度查询</h1>
                <div class="recruit-form">
                    <Form :model="formItem" :label-width="100" :label-position="labelPosition" :rules="ruleValidate" ref="formItem" style="width:70%;margin-left:15%;">
                        <FormItem label="项目名称" prop="project_title">
                            <Input v-model="formItem.project_title" style="margin-left:-60px;" placeholder="请输入项目名称"></Input>
                        </FormItem>
                        <FormItem label="项目编号" prop="project_number">
                            <Input v-model="formItem.project_number"  style="margin-left:-60px;" placeholder="请输入项目编号"></Input>
                        </FormItem>

                        <FormItem label="负责人员" prop="project_user">
                            <Input v-model="formItem.project_user" style="margin-left:-60px;" placeholder="请输入项目负责人"></Input>
                        </FormItem>

                    <div class="form-button" style="margin-bottom:30px;">
                        <FormItem> <Button type="primary" @click="handleSubmit('formItem')" style="width:120px;">立即查询</Button> </FormItem>
                    </div>
                    </Form>
                </div>

                <!--项目进度展示开始-->
               <div id="propess" class="left clearfix">
               
                <h2>关于宏泰基金电力工程项目</h2>

                <div class="jindutiao left clearfix">
                    <Steps :current="data.project_step">
                    <Step title="项目进场"></Step>
                    <Step title="项目中期验收"></Step>
                    <Step title="进度款申请"></Step>
                    <Step title="竣工验收"></Step>
                    <Step title="竣工结算"></Step>
                    <Step title="项目结项"></Step>
                </Steps>
                </div>

                <div class="project_text left clearfix">
                    <table cellspacing="0" cellspadding="0" style="100%">
                        <tr>
                            <td class="table_title" style="border-bottom:none;border-right:none;">发布时间</td>
                            <td style="border-bottom:none;border-right:none;">{{data.add_time}}</td>
                            <td class="table_title" style="border-bottom:none;border-right:none;">负责人</td>
                            <td style="border-bottom:none;">{{data.project_user}}</td>
                        </tr>
                        <tr>
                            <td class="table_title" style="border-bottom:none;border-right:none;">项目编号</td>
                            <td style="border-bottom:none;border-right:none;">{{data.project_number}}</td>
                            <td class="table_title" style="border-bottom:none;border-right:none;">项目进展</td>
                            <td style="border-bottom:none;">{{data.project_progress}}</td>
                        </tr>
                        <tr>
                            <td  class="table_title" style="border-right:none;">项目介绍</td>
                            <td colspan="3" style="padding:20px;text-align:left;"> {{data.project_content}} </td>
                        </tr>
                    </table>
                </div>
                </div>
                </div>
                </div>
  <div class="left clearfix width-bai"> <Footer></Footer> </div>
  <contact-call></contact-call>
</div>
</template>

<style>
    #propess{display:none;width:100%;}
    .table_title{font-weight:bold;}
    .project_text{width:100%;}
    .project_text table tr td{width:25%;height:50px;border:1px solid #eee;text-align:center;font-size:14px;line-height:25px;}
    .project_text table{width:90%;margin-left:5%;margin-top:50px;}
    .jindutiao{margin-top:60px;width:90%;margin-left:5%;}
    h2{line-height:50px;text-align:center;font-size:16px;border:1px solid #eee;margin-top:100px;}
    .ivu-form-item-content{margin-top:10px;}
    .ivu-form-item-error-tip{margin-left:0px;line-height:20px;margin-left:-60px;}
    .ivu-form .ivu-form-item-label{padding-top:3px;font-weight:bold;margin-top:10px;}
    .recruit-box h1{font-size:15px;border-left:5px solid rgb(242,151,0);padding-left:10px;margin-bottom:40px;}
    .contact-text{width:100%;min-height:800px;margin-top:20px;}
    .contact-contents{min-height:900px;width:1000px;margin-top:10px;font-size:16px;box-shadow: 0px 2px 5px #cccccc;margin-top:50px;padding-bottom:50px;}
    .contact-content{min-height:900px;}
    .form-label input{width:23%;margin-left:1%;margin-right:1%;line-height:30px;border:1px solid #ddd;border-radius:5px;padding-left:5px;}
    .form-button{width:100%;height:60px;margin-top:30px;text-align:center;}
    .form-button Button{margin-top:50px;margin-bottom:50px;margin-right:20px;}
    .form-label{width:100%;line-height:40px;}
    .form-label label{width:23%;float:left;margin-left:1%;margin-right:1%;text-align:center;font-weight:bold;font-size:15px;}
    .form-title{width:100%;line-height:60px;font-weight:bold;font-size:15px;}
</style>

<script>
import $ from 'jquery'
import ContactCall from '../index/ContactCall.vue'
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'

export default {
data () {
return {
  labelPosition:'top',
  data:'',

  formItem: {
      project_title:'',
      project_number:'',
      project_user:'',
  },

  ruleValidate: {
      project_number: [
          { required: true, message: '请填写项目编号', trigger: 'blur' }
      ]
  }
}
},

components:{
  ContactCall,
  Header,
  Footer
},

mounted(){
  //判断是否已经登录
  if(!this.$store.state.userinfo){
    $('#login').show();
    $('.alert_colse').hide();
  }
},

methods: {
handleSubmit (name) {
    $('#propess').hide();

    this.$refs[name].validate((valid) => {
        if (valid) {
            this.formItem.token=this.$store.state.token;
            var param=JSON.stringify(this.formItem);
            param = eval('(' + param + ')');
            this.$axios({method:'post',url:window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_project_progress.html',params:param})
						.then(res=>{
               if(res.data.code=='000'){
                 if(res.data.result.id){
                    this.data=res.data.result;
                    $('#propess').show();
                 }else{
                     this.$Modal.error({
                        title: '温馨提示',
                        content: '没有查询到对应的记录'
                    });
                 }
               }
            });
            
        } 
    })
}
            
}

}
</script>

